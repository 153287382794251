import React from "react"
import styled from "styled-components"

import map from "lodash/map"
import { device, gradientBorder } from "utils"

import { PrimaryNav, MobileNav } from "src/components/navigation"
import { Link } from "components/common"
import { PostTags, Tag } from "src/components/post"

import ArrowBack from "src/images/common/arrow-back.inline.svg"

import {
  Layout,
  Seo,
  VSection,
  ScrollProgress,
  HContainer,
} from "components/common"

import {
  useMeta,
  useRichText,
  // device,
  richTextBody,
  matrixBackgroundStyle,
  useReadingTime,
} from "utils"

const PostTemplate = ({ post, slugBase }) => {
  const {
    title,
    subtitle,
    categories,
    date,
    image,
    body,
    metaTitle,
    metaDescription,
    metaKeywords,
  } = post

  const [ref, time] = useReadingTime()

  const meta = useMeta({
    metaTitle,
    metaDescription,
    metaImage: image,
    metaKeywords,
  })

  const renderBody = useRichText({ text: body })

  return (
    <Layout>
      <Seo {...meta} />
      <MobileNav />
      <PrimaryNav />
      <StyledPostTemplate>
        <div className="postHero">
          <VSection
            titleAs="h1"
            title={title}
            subtitle={subtitle}
            media={{ desktop: image }}
            critical
            custom={() => (
              <>
                <div className="hat">
                  <h4 className="date">{date}</h4>
                  <h4 className="time">{time}</h4>
                </div>
                <div className="arrowBackWrap">
                  <Link to={slugBase} className="arrowBack">
                    <ArrowBack /> Back
                  </Link>
                </div>
              </>
            )}
          />
        </div>

        <ScrollProgress>
          <StyledBody>
            <HContainer>
              <div className="postBody" ref={ref}>
                {renderBody}
              </div>

              <PostTags>
                {map(categories, (category) => (
                  <Link key={category} to={`${slugBase}?f=${category}`}>
                    <Tag className="active" slug={category.toLowerCase()}>
                      {category}
                    </Tag>
                  </Link>
                ))}
              </PostTags>
            </HContainer>
          </StyledBody>
        </ScrollProgress>
      </StyledPostTemplate>
    </Layout>
  )
}

const StyledPostTemplate = styled.div`
  --section-max-width: 800px;

  .postHero {
    position: relative;
    --section-padding-top: var(--sp-nav);
    --section-mobile-padding-top: var(--sp-80);
    --section-padding-bottom: 0;
    --section-mobile-padding-bottom: 0;

    .arrowBackWrap {
      position: absolute;
      top: -56px;
      a {
        display: inline-block;
        display: flex;
        align-items: center;

        svg {
          margin-right: var(--sp-8);
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .v_tgContainer {
      --section-order: 2;
      --section-mobile-order: 2;
    }
    .v_customContainer {
      --section-order: 1;
      --section-mobile-order: 1;
      .hat {
        display: flex;

        grid-template-columns: 1fr;
        margin-bottom: var(--sp-16);
        flex-direction: column;
        @media ${device.laptop} {
          flex-direction: row;
          justify-content: flex-start;
        }

        .date {
          line-height: 1;
          text-transform: uppercase;
          @media ${device.laptop} {
            border-right: 2px solid var(--highlight_1);
            padding-right: var(--sp-8);
            margin-right: var(--sp-8);
          }
        }
        .time {
          line-height: 1;
          text-transform: uppercase;
          margin-top: var(--sp-8);
          @media ${device.laptop} {
            margin-top: 0;
            margin-left: var(--sp-8);
          }
        }
      }
    }

    .v_mediaContainer {
      --v-section-media-top: 0;
      --v-section-mobile-media-top: 0;
      margin-top: -7vh;

      @media ${device.tablet} {
        margin-top: -10vh;
      }
      @media ${device.laptop} {
        margin-top: -211px;
      }

      .gatsbyImageWrap {
        width: 100%;
        height: 100%;

        transform: translateY(12vh);
        @media ${device.tablet} {
          transform: translateY(15vh);
        }

        @media ${device.laptop} {
          transform: translateY(267px);
        }

        .gatsby-image-wrapper {
          border-radius: var(--card-radius);
          ${gradientBorder({
            background: "var(--card-shade_1)",
            gradient: "var(--opero-gradient)",
          })};
          @media ${device.laptop} {
            max-height: 535px;
          }

          img {
            border-radius: var(--card-radius);
          }
        }
      }
    }
  }

  .v_backgroundImage {
    ${matrixBackgroundStyle};
  }
`

const StyledBody = styled.section`
  background: var(--lightBG);
  padding-bottom: 80px;

  padding-top: 15vh;
  @media ${device.tablet} {
    padding-top: 20vh;
  }
  @media ${device.laptop} {
    padding-top: 300px;
    padding-bottom: 160px;
  }

  .postBody {
    ${richTextBody};
    --primary-font-color: var(--card-shade_1);

    .videoWrap {
      border-radius: var(--card-radius);

      iframe {
        width: 100%;
        border-radius: var(--card-radius);
      }

      video {
        border-radius: var(--card-radius);
      }
    }

    a {
      color: var(--link_light);
    }
  }

  .blogTags {
    margin-top: var(--sp-56);
  }
`

export default PostTemplate
