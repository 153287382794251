import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import PostTemplate from "./PostTemplate"

const BlogPost = ({ data: { blogPost, backgroundImage } }) => {
  return (
    <StyledBlogPost>
      <PostTemplate
        post={blogPost}
        backgroundImage={backgroundImage}
        slugBase="/blog"
      />
    </StyledBlogPost>
  )
}

const StyledBlogPost = styled.div``

export default BlogPost

export const query = graphql`
  query BlogPostSlug($slug: String!) {
    blogPost: contentfulBlogPost(slug: { eq: $slug }) {
      ...BlogPost
    }
    backgroundImage: contentfulMedia(mediaId: { eq: "dot_matrix" }) {
      ...Media
    }
  }
`
